import { palette } from '@smile/tokens/palette';
import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';

import * as styles from './styles';
import type { DecorationProps, LayoutThemeType, ThemeProps } from './types';

const pickDecorationColors = (
	theme: LayoutThemeType,
): Record<string, string> => {
	switch (theme) {
		case 'light':
			return { decoration: palette.baseBlue[500] };
		case 'dark':
			return { decoration: palette.base.white };
	}
};

export const Decoration: FC<
	PropsWithChildren<DecorationProps & ThemeProps & { noPagination?: boolean }>
> = ({ backgroundColorOverride, theme, noPagination, children }) => {
	const { decoration } = pickDecorationColors(theme);

	return (
		<div
			className={clsx(
				styles.decoration,
				noPagination ? styles.decorationWithoutPagination : '',
			)}
			style={{
				backgroundColor: backgroundColorOverride || decoration,
			}}
		>
			{children}
		</div>
	);
};
