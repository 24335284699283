import type { ImpressionSequence, OfferSlot } from '@thanks/impression-type';
import { useMemo } from 'react';

const adoptEnterScreen = (
	brand: ImpressionSequence['brand'],
	enterScreen: ImpressionSequence['enterScreen'],
): OfferSlot => {
	if (enterScreen) {
		// has to use switch to explain variations to TS
		switch (enterScreen.type) {
			case 'splash':
				return {
					type: 'splash',
					data: {
						...brand,
						...enterScreen,
						experienceId: 'splash',
					},
				};
			case 'trivia':
				return {
					type: 'trivia',
					data: {
						...brand,
						...enterScreen,
						experienceId: 'trivia',
					},
				};
			default:
				const exhaustiveCheck: never = enterScreen;
				throw new Error(`Unhandled case: ${exhaustiveCheck}`);
		}
	}

	// legacy
	if (brand.type === 'splash') {
		return {
			type: 'splash',
			data: brand,
		} as const;
	}

	return {
		type: 'splash',
		data: {
			...brand,
			type: 'skip',
		},
	};
};

export const useSlots = (impression: ImpressionSequence): OfferSlot[] => {
	return useMemo(
		() => [
			adoptEnterScreen(impression.brand, impression.enterScreen),
			...impression.slots,
		],
		// TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[impression.slots],
	);
};
