import type { MarkdownAstNode } from '@theway/markdown/types';
import { fromMarkAst } from '@theway/markdown-ui';

export const astToNode = (ast: string | MarkdownAstNode) => {
	if (typeof ast === 'string') {
		return ast;
	}

	return fromMarkAst(ast);
};
