import { encodePersonalToken } from '@smile/personal-token';
import type {
	PersonalInformationRequestDetails,
	PersonalInformationRequestSubject,
	PersonalInformationTransfer,
} from '@thanksjs/web';
import { captureException } from '@theway/metrics';
import { getPiiId } from '@theway/uid';

import { analytics } from '../../analytics';
import { tunnel } from '../../bridge';

export const getPiiToken = async ({
	impression,
	piiRegion,
	subject,
	offerType,
	offer,
	customerSharedEmail,
}: {
	impression: string;
	piiRegion: string;
	subject: PersonalInformationRequestSubject;
	offerType: 'coupon' | 'subscription' | 'transaction';
	offer: {
		partnerName: string;
		experienceId: string;
	};
	customerSharedEmail: undefined | string;
}): Promise<string | undefined> => {
	const token = getPiiId('collected', piiRegion);
	const info: PersonalInformationRequestDetails = {
		token,
		offerType: offerType,
		advertiser: offer.partnerName,
	};

	return new Promise((resolve) => {
		const off = tunnel.on(
			'personal-information-response',
			({
				info,
				pii,
			}: {
				info: PersonalInformationRequestDetails;
				pii: PersonalInformationTransfer;
			}) => {
				const analyticsProperties = {
					offerId: offer.experienceId,
					subject,
					offerType,
				};

				if (info.token !== token) {
					console.error('PII token mismatch', info.token, token);

					captureException(new Error('PII token mismatch'));

					analytics.ad.piiResponse({
						...analyticsProperties,
						success: false,
						reason: 'pii token mismatch',
					});

					return;
				}

				off();

				if (!pii) {
					if (customerSharedEmail) {
						resolve(
							encodePersonalToken(impression, {
								piId: token,
								email: customerSharedEmail,
							}),
						);
					} else {
						analytics.ad.piiResponse({
							...analyticsProperties,
							success: false,
							reason: 'no pii provided by publisher',
						});

						resolve(undefined);
					}

					return;
				}

				analytics.ad.piiResponse({
					...analyticsProperties,
					success: true,
					fields: Object.keys(pii).join(','),
				});

				resolve(
					encodePersonalToken(impression, {
						piId: token,
						firstName: pii.firstName,
						email: pii.email,
					}),
				);
			},
		);

		tunnel.send('personal-information-request', { subject, info });
	});
};
