import { heimdallClientSees } from '@theway/heimdall/client';
import UserFlux from '@userflux/browser-js-lite';

export const afterUFInit = () => {
	// @ts-expect-error untyped access
	const { taint } = (typeof window !== 'undefined' && window.__env__) || {};
	if (taint !== 'e2e') return;

	// @ts-expect-error - undocumented prop
	const q: any[] = UserFlux.ufTrackQueue;

	// we bypass queue sending events only to Heimdall
	q.push = (...events: any[]) => {
		events.forEach((event) => {
			heimdallClientSees('userflux:client', event);
		});

		return 1;
	};
};

export { UserFlux };
