import { useCustomerCommunication } from '@smile/context';
import { decodePersonalToken } from '@smile/personal-token';
import { captureException } from '@theway/metrics';
import { useEffect, useState, useRef } from 'react';

import { getPiiToken } from '../services/pii/pii-bridge';

export const useVisibilityState = () => {
	const [visible, setVisible] = useState(
		document.visibilityState === 'visible',
	);

	useEffect(() => {
		const cb = () => {
			setVisible(document.visibilityState === 'visible');
		};

		document.addEventListener('visibilitychange', cb);

		return () => document.removeEventListener('visibilitychange', cb);
	}, []);

	return visible;
};

export const useSyncingRef = <T>(value: T) => {
	const ref = useRef(value);
	ref.current = value;

	return ref;
};

export type PrefilledInfo = {
	email?: string | undefined;
	firstName?: string | undefined;
};

export const usePrefilled = ({
	impressionId,
	piiRegion,
	offer,
}: {
	impressionId: string;
	piiRegion: string;
	offer: {
		partnerName: string;
		experienceId: string;
	};
}) => {
	const [prefilled, setPrefilled] = useState<PrefilledInfo | undefined>(
		undefined,
	);

	const { customerSharedEmail } = useCustomerCommunication();

	useEffect(() => {
		const getToken = getPiiToken({
			impression: impressionId,
			piiRegion,
			subject: 'autofill',
			offerType: 'subscription',
			offer,
			customerSharedEmail,
		});

		getToken
			.then((token) => {
				if (!token) {
					return;
				}

				const { email, firstName } = decodePersonalToken(
					impressionId,
					token,
				);
				setPrefilled({ email, firstName });
			})
			.catch((e) => {
				console.error('Failed to get prefilled info', e);

				captureException(new Error(e));
			});
	}, [customerSharedEmail, impressionId, offer, piiRegion]);

	return { prefilled };
};
