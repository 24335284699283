import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';
import { from, Media, until } from '@theway/helpers-css/breakpoints';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';

export const component = css`
	display: flex;
	flex-direction: column;

	padding-inline: 24px;
	padding-top: 30px;
	padding-bottom: 32px;

	${container('content', 'width < 358px')} {
		padding-inline: 20px;
	}

	${from(Media.SM)} {
		width: 422px;
		padding-inline: 40px;
		padding-top: 24px;
		padding-bottom: 0;
	}
`;

export const textWrapper = css`
	display: flex;
	flex-direction: column;
	row-gap: 12px;

	${until(Media.SM)} {
		align-items: center;
	}
`;

export const text = css`
	text-align: center;

	${from(Media.SM)} {
		text-align: left;
	}
`;

export const subText = css`
	text-align: center;

	${from(Media.SM)} {
		text-align: left;
	}
`;

export const list = css`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	row-gap: 12px;

	width: 100%;
	margin-top: 12px;
`;

export const item = css``;

export const pres = css`
	position: relative;
	width: 36px;
	height: 36px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	border: 2px solid #002140;

	background-color: white;
`;

export const letter = css`
	text-transform: capitalize;
`;

export const answerText = css`
	position: relative;
	z-index: 1;
`;

export const answer = css`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 16px;

	width: 100%;
	height: 48px;
	padding-inline: 12px;
	padding-block: 8px;

	background-color: ${palette.base.white};
	border-radius: 30px;
	border: 1px solid #d0d5dd;

	overflow: hidden;

	&::before {
		position: absolute;
		inset-block: 0;
		left: 0;
		right: 100%;
		content: '';

		display: block;
		width: auto;

		background-color: #ffd1d1;
		border-radius: 6px;

		transition-timing-function: linear;
		transition-duration: 500ms;
		transition-property: right;
	}

	& .${pres}::before {
		position: absolute;
		inset: -2px;
		content: '';

		display: none;
		width: calc(100% + 4px);
		height: calc(100% + 4px);

		border-radius: 50%;
		background-image: url('./assets/cross.svg');
	}
`;

export const correct = css`
	&::before {
		background-color: #e1ffc8;
	}

	& .${pres}::before {
		background-image: url('./assets/check.svg');
	}
`;

export const active = css`
	& .${pres} {
		background-color: transparent;
	}

	& .${pres}::before {
		display: block;
	}

	& .${pres} .${letter} {
		display: none;
	}

	&::before {
		right: 0;
	}
`;

export const selected = css`
	border: 2px solid ${palette.baseBlue[500]};
`;
