import type { FC, PropsWithChildren, ReactElement } from 'react';
import { createElement, Fragment } from 'react';

import type { MarkdownAstNode } from './types';

type SUPPORTED_COMPONENTS = 'strong' | 'emphasis';

export type AstComponents = Partial<{
	strong: FC<PropsWithChildren> | undefined;
	delete: FC<PropsWithChildren> | undefined;
	emphasis: FC<PropsWithChildren> | undefined;
	paragraph: FC<PropsWithChildren> | undefined;
	link: FC<PropsWithChildren<{ url: string }>> | undefined;
	image: FC<PropsWithChildren<{ url: string; alt: string }>> | undefined;
}>;

export const fromAst = (
	ast: MarkdownAstNode,
	components: AstComponents,
): ReactElement => {
	const { t: type, c, v, ...props } = ast;

	const payload = c ? (
		<>
			{c.map((ch, index) => (
				<Fragment key={index}>{fromAst(ch, components)}</Fragment>
			))}
		</>
	) : (
		v
	);

	if (['root', 'text'].includes(type)) {
		return payload as ReactElement;
	}

	if (type === 'html') {
		return createElement('html-in', {
			dangerouslySetInnerHTML: { __html: v },
		});
	}

	if (!components[type as SUPPORTED_COMPONENTS]) {
		// console.warn('missing component for', type);

		if (type === 'paragraph') {
			return payload as ReactElement;
		}
	}

	return createElement(
		components[type as SUPPORTED_COMPONENTS] || Fragment,
		props,
		payload,
	);
};

export const reduceAstToSimpleString = (
	ast: MarkdownAstNode | string,
): string => {
	if (typeof ast === 'string') {
		return ast;
	}

	return ast.c
		? ast.c.map((ch) => reduceAstToSimpleString(ch)).join('')
		: ast.v || '';
};

export const liftHTMLIN = (strin: string): string =>
	strin.replaceAll('<html-in>', '').replaceAll('</html-in>', '');
