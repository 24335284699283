import type { FC } from 'react';

import { Trivia } from './Trivia';

export const HumanitixTrivia: FC<{
	onGetStarted(): void;
	onChoiceSelect(option: string, correct: boolean): void;
}> = ({ onGetStarted, onChoiceSelect }) => {
	return (
		<Trivia
			trivia={{
				text: 'Test your knowledge and unlock 5 offers',
				question:
					'How much of its profits does Humanitix dedicate to charity?',
				options: [
					{ letter: 'A', answer: '25%', correct: false },
					{ letter: 'B', answer: '50%', correct: false },
					{ letter: 'C', answer: '100%', correct: true },
				],
			}}
			onGetStarted={onGetStarted}
			onChoiceSelect={onChoiceSelect}
		/>
	);
};
