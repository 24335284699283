import type { SurfaceColorType } from '@smile/ds/surface';
import { Surface } from '@smile/ds/surface';

import clsx from 'clsx';
import type { FC, PropsWithChildren } from 'react';

import { ContextContentTeleporter } from '../control/teleporters';
import type { FooterProps } from '../footer';
import { Footer } from '../footer';

import * as styles from './styles';
import type { LayoutThemeType, ThemeProps } from './types';

const pickColors = (
	theme: LayoutThemeType,
): Record<string, SurfaceColorType> => {
	switch (theme) {
		case 'light':
			return { content: 'primary' };
		case 'dark':
			return { content: 'tertiary' };
	}
};

export const Content: FC<
	PropsWithChildren<FooterProps & ThemeProps> & { noPagination?: boolean }
> = ({
	children,
	theme,
	terms,
	emailNotification,
	experienceId,
	charityTerms,
	displayCharity = false,
	noPagination = false,
}) => {
	const { content } = pickColors(theme);

	return (
		<Surface
			as="div"
			color={content}
			className={clsx(
				styles.content,
				noPagination ? styles.contentWithoutPagination : '',
			)}
		>
			<div>
				<ContextContentTeleporter.Target />
				<ContextContentTeleporter.HiddenFallback>
					<div className={styles.contentWrapper}>{children}</div>
				</ContextContentTeleporter.HiddenFallback>
			</div>
			<Footer
				experienceId={experienceId}
				terms={terms}
				emailNotification={emailNotification}
				charityTerms={charityTerms}
				displayCharity={displayCharity}
			/>
		</Surface>
	);
};
