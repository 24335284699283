import { registerErrorHandler } from '@theway/metrics';
import { Sentry, injectSentry } from '@theway/sentry';

const dsn =
	'https://45fab2af452e4d59a467dcfc11928b58@o4504970692853760.ingest.sentry.io/4504970697834496';

export const initSentry = () => {
	injectSentry(dsn);

	Sentry().onLoad(() => {
		Sentry().init({
			dsn,
			// integrations: [new Sentry.BrowserTracing()],
			// tracesSampleRate: 1.0,
			enabled: !import.meta.env.DEV,
			environment: import.meta.env.ENVIROMENT,
			release: import.meta.env.COMMIT_HASH || 'unknown',
		});

		registerErrorHandler((e) => Sentry().captureException(e));

		Sentry().setTags({
			publisherId: window.$impression.analyticsPartnerSiteId,
			country: window.$impression.country,
		});

		Sentry().setExtras({
			impressionId: window.$impression.impressionId,
			traceId: window.$impression.analytics?.traceId,
		});
	});
};
