import type { init, setTags, setExtras } from '@sentry/browser';

export { injectSentry } from './injection';

type SentryType = {
	init: typeof init;
	onLoad: (callback: () => void) => void;
	captureException: (e: Error) => void;
	setTags: typeof setTags;
	setExtras: typeof setExtras;
};

// @ts-expect-error - Sentry is injected by the script tag
export const Sentry = (): SentryType => window.Sentry;
