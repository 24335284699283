import type { OfferEffectActions } from '@smile/widget-common/types';
import type { ImpressionSequence, OfferSlot } from '@thanks/impression-type';

import { useEffect } from 'react';

import { analytics } from '../../analytics';
import { getAdTrackingAttributes } from '../../utils/ad-analytics';

export const getCommonProps = (
	attributes: {
		slot: OfferSlot;
		impression: ImpressionSequence;
		position: number;
		stepEnterTime: number;
		jumpWidth: number;
	},
	callbacks: { advance(jump: number): void },
): OfferEffectActions => ({
	onPrimaryAction: (options = {}) => {
		const defaultAttributes = getAdTrackingAttributes(
			attributes.slot,
			attributes.position,
			attributes.impression,
		);

		analytics.ad.action(attributes.slot.data.experienceId, undefined, {
			...defaultAttributes,
			order: options.order || 0,
			advertiserId:
				options?.advertiserId || defaultAttributes.advertiserId,
			timeVisible: Date.now() - attributes.stepEnterTime,
		});
	},
	onSecondaryAction: (type: string) => {
		analytics.ad.action(attributes.slot.data.experienceId, type, {
			...getAdTrackingAttributes(
				attributes.slot,
				attributes.position,
				attributes.impression,
			),
			timeVisible: Date.now() - attributes.stepEnterTime,
		});
	},
	onForward: () => {
		// advance while user in another tab
		setTimeout(() => callbacks.advance(attributes.jumpWidth), 300);
	},
	onNext: () => {
		analytics.ad.skip(attributes.slot.data.experienceId, {
			...getAdTrackingAttributes(
				attributes.slot,
				attributes.position,
				attributes.impression,
			),
			timeVisible: Date.now() - attributes.stepEnterTime,
		});

		callbacks.advance(attributes.jumpWidth);
	},
});

export const useFirstImpression = (
	slotZero: { type: string },
	initialStep: number,
) => {
	const type = slotZero.type;

	useEffect(() => {
		analytics.widget.action('first-step', {
			state: initialStep > 0 ? 'skip' : type,
		});
	}, [type, initialStep]);
};
