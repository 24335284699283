import type { AnalyticsAdapter } from '@theway/analytics';
import { lazyBridge } from '@theway/lazy-bridge';

import { trackView } from '../utils/tracking/tracking-connector';

import { getAnalyticsFinalization } from './finalizers';

const coordinates = {
	impression: '',
	source: '',
	hash: '',
};

const adapters: AnalyticsAdapter[] = [];

export const addAnalyticsAdapter = (adapter: AnalyticsAdapter) => {
	adapters.push(adapter);
};

export const addLazyAnalyticsAdapter = (
	adapterLoader: () => Promise<AnalyticsAdapter>,
) => {
	adapters.push(lazyBridge(adapterLoader));
};

export const analytics: AnalyticsAdapter = {
	identify(impression, source, options) {
		Object.assign(coordinates, {
			impression,
			source,
			hash: options.hash,
		});

		adapters.forEach((a) => a.identify(impression, source, options));
	},
	flush() {
		return Promise.all([
			...adapters.map((a) => a.flush()),
			...getAnalyticsFinalization(),
		]);
	},
	heartBeat(position, type, experienceId) {
		adapters.forEach((a) => a.heartBeat(position, type, experienceId));
	},
	phaseMetric(phase, attributes) {
		adapters.forEach((a) => a.phaseMetric(phase, attributes));
	},
	uiEvent(event) {
		adapters.forEach((a) => a.uiEvent(event));
	},
	embed: {
		view(attributes) {
			adapters.forEach((a) => a.embed.view(attributes));
			analytics.phaseMetric('embed-display', attributes);
		},
		action(attributes) {
			adapters.forEach((a) => a.embed.action(attributes));
			analytics.phaseMetric('embed-action', attributes);
		},
	},
	ad: {
		exposure(adId, attributes) {
			adapters.forEach((a) => a.ad.exposure(adId, attributes));
		},
		view(adId, attributes) {
			adapters.forEach((a) => a.ad.view(adId, attributes));

			trackView(coordinates.impression, adId);
		},
		action(adId, type, attributes) {
			adapters.forEach((a) => a.ad.action(adId, type, attributes));
		},
		skip(adId, attributes) {
			adapters.forEach((a) => a.ad.skip(adId, attributes));
		},
		piiResponse(attributes) {
			adapters.forEach((a) => a.ad.piiResponse(attributes));
		},
	},
	widget: {
		action(name, attributes) {
			adapters.forEach((a) => a.widget.action(name, attributes));
		},
	},
	charity: {
		choice(charityName) {
			adapters.forEach((a) => a.charity.choice(charityName));
		},
		view() {
			adapters.forEach((a) => a.charity.view());
		},
	},
};
