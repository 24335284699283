import { palette } from '@smile/tokens/palette';
import type { FC, PropsWithChildren } from 'react';

import { DesktopCharity } from '../charity/Charity';
import { ContextPresentationTeleporter } from '../control/teleporters';
import { Header } from '../header';
import { Partnership } from '../partnership';

import { Content } from './Content';
import { Decoration } from './Decoration';
import { DualPartners } from './DualPartners';
import { Presentation } from './Presentation';
import { ShareButton } from './Share';
import * as styles from './styles';
import type { CommonProps } from './types';

export const Desktop: FC<
	PropsWithChildren<CommonProps> & { onClose(): void }
> = ({
	children,
	noPagination = false,
	withLogos = true,
	onClose,
	...props
}) => {
	return (
		<div className={styles.desktop}>
			<div className={styles.wrapper}>
				<Decoration
					backgroundColorOverride={props.backgroundColorOverride}
					theme={props.theme}
				>
					<Presentation
						hidden={props.view ? props.view[1] === 'plain' : false}
						currentImage={props.currentImage}
						previousImage={props.previousImage}
					/>
					<div style={{ position: 'absolute', inset: 0 }}>
						<ContextPresentationTeleporter.Target>
							<>
								{withLogos && props.brand && (
									<div className={styles.partnership}>
										<Partnership
											brand={props.brand}
											logoWidth={88}
											partnerColor={palette.base.white}
										/>
									</div>
								)}
								{props.offerType === 'dual' && (
									<DualPartners
										height={88}
										firstBrand={props.firstBrand}
										secondBrand={props.secondBrand}
									/>
								)}
								<ShareButton onShare={props.onShare} />
							</>
						</ContextPresentationTeleporter.Target>
					</div>
				</Decoration>
				<Content
					theme={props.theme}
					terms={props.terms}
					emailNotification={props.emailNotification}
					experienceId={props.experienceId}
					charityTerms={props.charity?.terms}
					displayCharity={props.displayCharity || false}
				>
					<Header {...props} noPagination={noPagination}>
						{props.statusText}
					</Header>
					{children}
					{props.charity && props.displayCharity && (
						<DesktopCharity
							onClose={onClose}
							displayCharity={props.displayCharity || false}
							onBackToOffer={props.onBackToOffer || (() => null)}
							onDonateToCharity={
								props.onDonateToCharity || (() => null)
							}
							charityList={props.charity.list}
							period={props.charity.period}
							amount={props.charity.amount}
							splashImage={props.splashImage || ''}
							statusText={props.statusText}
						/>
					)}
				</Content>
			</div>
		</div>
	);
};
