import { TextMd, Typography } from '@smile/ds/typography';
import { EventContainer } from '@theway/analytics';
import clsx from 'clsx';
import { useCallback, useState, type FC, type ReactNode } from 'react';

import * as styles from './styles';

type OptionType = {
	letter: string;
	answer: string;
	correct: boolean;
};

type TriviaType = {
	text: ReactNode;
	question: ReactNode;
	options: OptionType[];
};

type ContentProps = {
	trivia: TriviaType;
	onGetStarted(): void;
	onChoiceSelect(option: string, correct: boolean): void;
};

export const TriviaContent: FC<ContentProps> = ({
	onGetStarted,
	onChoiceSelect,
	trivia,
}) => {
	const [select, setSelect] = useState<OptionType | null>(null);

	const handleSelect = useCallback(
		(opt: OptionType) => {
			setSelect(opt);

			onChoiceSelect(opt.letter, opt.correct);

			setTimeout(() => {
				onGetStarted();
			}, 1600);
		},
		[onGetStarted, onChoiceSelect],
	);

	return (
		<div className={styles.component}>
			<div className={styles.textWrapper}>
				<Typography
					as="h2"
					className={styles.text}
					weight={'black'}
					size={'h2'}
				>
					{trivia.text}
				</Typography>
				<TextMd as="p" className={styles.subText}>
					{trivia.question}
				</TextMd>
				<ul className={styles.list}>
					{trivia.options?.map((opt: OptionType) => {
						return (
							<li className={styles.item} key={opt.answer}>
								<button
									className={clsx(
										styles.answer,
										select === opt && styles.selected,
										select && styles.active,
										opt?.correct && styles.correct,
									)}
									onClick={() => handleSelect(opt)}
								>
									<TextMd as="span" className={styles.pres}>
										<span className={styles.letter}>
											{opt.letter}
										</span>
									</TextMd>
									<TextMd
										as="span"
										className={styles.answerText}
									>
										{opt.answer}
									</TextMd>
								</button>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export const Trivia: FC<ContentProps> = (props) => {
	return (
		<EventContainer name="Trivia">
			<TriviaContent {...props} />
		</EventContainer>
	);
};
