import {
	Layout,
	Thanks,
	OfferLayout,
	type BehaviorMode,
	type ViewMode,
	type CharityProps,
} from '@meleagris-gallopavo/widget';
import { HumanitixTrivia } from '@meleagris-gallopavo/widget/trivia';
import { useCustomerCommunication } from '@smile/context';
import { useFeatureFlag } from '@smile/experimentation';
import { pickRandom, pickRandoms } from '@smile/experiments';
import type { ImpressionSequence } from '@thanks/impression-type';
import {
	EventContainer,
	useGlobalAnalyticsAttributes,
} from '@theway/analytics';
import { fromMarkAst } from '@theway/markdown-ui';
import { type FC, useCallback, useState } from 'react';
import { Fragment, Suspense, useMemo } from 'react';

import { analytics } from '../../analytics';
import { useStepPreload } from '../../components/preload';
import { useFlowSteps } from '../../components/use-flow';
import {
	getAdTrackingAttributes,
	useExposureTracking,
} from '../../utils/ad-analytics';
import { DesktopWrapper, MobileWrapper } from '../../views/layout';

import { useFirstImpression } from '../components/analytics';
import { astToNode } from '../components/ast-utils';
import { useAdProps } from '../components/component-adoption';

import { useStepImages } from '../components/hooks';
import { useSlots } from '../components/slots';
import { renderStepX1 } from '../components/step-renderer-x1';

import { SuspendedRender } from '../components/utils';

import { CharityOrgs } from './charity-list';
import { preload as preloadStep } from './load-control';
import { slotRender } from './meleagris-gallopavo-broker';

const EBAY_SPLASH_DEMO_PUBLISHER_ID = 'f134d1f2-952c-4c83-9113-3c1e1801307d';
// const HUMANITIX_US_PUBLISHER_ID = 'd6f25b6e-02fb-4c3b-922a-56e84292f5c9';
// const HUMANITIX_AU_PUBLISHER_ID = 'cc05df69-5525-437f-b2b4-82c495c5f41d';
const TRY_BOOKING_PUBLISHER_ID = '3a49fbf4-23bd-496b-be14-c38a6013f580';

const WRAPPERS = {
	desktop: DesktopWrapper,
	mobile: MobileWrapper,
} as const;

// const IS_DEV = import.meta.env.VITE_ENVIRONMENT !== 'production';

export const MeleagrisGallopavoApp: FC<{
	mode: 'desktop' | 'mobile';
	impression: ImpressionSequence;
	offsetTop: number | undefined;
	onClose(): void;
	initialStep?: number;
}> = ({ mode, impression, onClose, offsetTop, initialStep = 0 }) => {
	const Wrapper = WRAPPERS[mode];

	const stepImages = useStepImages(impression);
	const slots = useSlots(impression);
	useFirstImpression(slots[0], initialStep);

	const [displayCharity, setDisplayCharity] = useState(false);

	const withCharity = [TRY_BOOKING_PUBLISHER_ID].includes(
		impression.partnerSiteId,
	);

	const onFinish = useCallback(() => {
		if (withCharity) {
			analytics.charity.view();
			setDisplayCharity(true);
		} else {
			onClose();
		}
	}, [onClose, withCharity]);

	const charityConfig = withCharity
		? ({
				charity: {
					list: pickRandoms(CharityOrgs, 3),
					period: 'month',
					amount: 1000,
					terms: 'Limited to one vote per user per day. Thanks will tally the votes on February 28th and will then announce the charity with the most votes on March 1. In the case where one or more charities receive the same number of votes, the donation will be equally shared between the winning charities.',
				},
				displayCharity,
				splashImage: impression.brand.mg?.image_desktop,
				onBackToOffer: () => setDisplayCharity(false),
				onDonateToCharity: (name) => {
					console.log('chosed', name);
					analytics.charity.choice(name);
				},
			} as CharityProps)
		: {};

	const {
		maximumViewedStep,
		onSetSlide,
		advance,
		stepEnterTime,
		step,
		stepLimit,
	} = useFlowSteps(initialStep, { onFinish, stepLimit: slots.length });

	const stepNavigationProps = {
		slides: stepImages,
		activeSlide: step,
		maximumSlide: Math.max(step, maximumViewedStep),
		onSetSlide,
	} as const;

	const renderStepLayout = (renderedStep: number) => {
		const slide = slots[renderedStep];
		const text =
			'data' in slide
				? 'text' in slide.data
					? fromMarkAst(slide.data.text)
					: '...'
				: '...';

		return <OfferLayout text={text} subtext="..." disclaimer={undefined} />;
	};

	const rendererStep = (renderedStep: number) => {
		return renderStepX1(
			renderedStep,
			{
				maximumViewedStep,
				stepLimit,
				slots,
				stepImages,
				onSetSlide,
				stepEnterTime,
				impression,
				advance,
				onClose,
				slotRender,
			},
			{
				Splash: (slideData, events, props) => (
					<Thanks
						{...props}
						{...slideData}
						{...events}
						text={fromMarkAst(slideData.text)}
						subtext={fromMarkAst(slideData.subtext)}
						onGetStarted={events.onNext}
						onClose={onClose}
					/>
				),
				Trivia: (events) => (
					<HumanitixTrivia
						onGetStarted={events.onNext}
						onChoiceSelect={(option, correct) => {
							analytics.widget.action('trivia-answer', {
								option,
								correct,
							});
						}}
					/>
				),
			},
		);
	};

	const onLayoutClose = () => {
		const slot = slots[step];

		analytics.widget.action('layout-close', {
			...getAdTrackingAttributes(slot, step, impression),
			timeVisible: Date.now() - stepEnterTime,
			category: '',
		});

		onClose();
	};

	const slot = slots[step];

	const slotImage = {
		url: (slot.data.mg || impression.brand.mg)?.image_desktop || '',
		xsUrl: (slot.data.mg || impression.brand.mg)?.image_mobile || '',
	};

	const adProps = useAdProps(slot);

	const backgroundColorOverride = useMemo(
		() => {
			const config = impression.brand.background;

			if (!config) {
				return undefined;
			}

			return pickRandom(config);
		},
		// TODO: fix eslint error
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useStepPreload(impression, preloadStep, step, slots.length);

	useGlobalAnalyticsAttributes(['ui', 'event'], {
		position: step,
		type: slot?.type,
		backgroundColor: backgroundColorOverride,
		partner: slot?.data.partnerName,
		get timeVisible() {
			return Date.now() - stepEnterTime;
		},
	});

	useExposureTracking(step, slots[step], impression);

	const plainStep0 = useFeatureFlag('use-plain-image-step0') && step === 0;
	const viewMode: ViewMode = plainStep0
		? ['plain', 'plain']
		: ['fancy', 'fancy'];
	const positionBehavior: BehaviorMode = useFeatureFlag('use-native-position')
		? ['native', 'native']
		: ['normal', 'normal'];

	const { communicationsAvailable } = useCustomerCommunication();

	return (
		<Wrapper offsetTop={offsetTop}>
			<EventContainer name="step" attribute={step}>
				<Layout
					circleExperiment={
						!!useFeatureFlag('display-circle-box-shadow')
					}
					view={viewMode}
					behaviour={positionBehavior}
					theme={slot?.type !== 'fact' ? 'light' : 'dark'}
					statusText={astToNode(
						impression.statusOverride[step] ||
							impression.statusText,
					)}
					onClose={onLayoutClose}
					currentImage={slotImage}
					previousImage={slotImage}
					brand={step === 0 ? impression.brand.logo : undefined}
					noPagination={slots[step]?.type === 'trivia'}
					withBrandItem={slots.some((slot) => slot.type === 'fact')}
					backgroundColorOverride={backgroundColorOverride}
					withLogos={
						impression.partnerSiteId !==
						EBAY_SPLASH_DEMO_PUBLISHER_ID
					}
					terms={
						slot && 'termsText' in slot.data
							? {
									text: slot.data.termsText,
									logo: slot.data.img.active,
									logoBg: slot.data.img.bg,
									logoStroke: slot.data.img.stroke,
								}
							: undefined
					}
					experienceId={slot.data.experienceId}
					emailNotification={
						// 'communicationAvailable' in slot.data &&
						// slot.data.communicationAvailable
						Boolean(communicationsAvailable)
					}
					badge={
						slot && 'badge' in slot.data
							? fromMarkAst(slot.data.badge)
							: undefined
					}
					{...charityConfig}
					{...stepNavigationProps}
					{...adProps}
				>
					<Fragment key={step}>
						<Suspense fallback={renderStepLayout(step)}>
							<SuspendedRender>
								{() => rendererStep(step)}
							</SuspendedRender>
						</Suspense>
					</Fragment>
				</Layout>
			</EventContainer>
		</Wrapper>
	);
};
