import { Layout, SplashScreen } from '@platycercus/widget';
import type { ImpressionSequence } from '@thanks/impression-type';
import { EventContainer } from '@theway/analytics';
import { fromMarkAst } from '@theway/markdown-ui';
import { type FC, type PropsWithChildren, Suspense } from 'react';

import { analytics } from '../../analytics';
import { useFlowSteps } from '../../components/use-flow';
import {
	getAdTrackingAttributes,
	useExposureTracking,
} from '../../utils/ad-analytics';

import { useFirstImpression } from '../components/analytics';
import { astToNode } from '../components/ast-utils';
import { useStepImages } from '../components/hooks';
import { useSlots } from '../components/slots';
import { renderStepX1 } from '../components/step-renderer-x1';

import { SuspendedRender } from '../components/utils';

import { slotRender } from './platycercus-broker';

const StepRender: FC<PropsWithChildren> = ({ children }) => children;

export const PlatycercusApp: FC<{
	impression: ImpressionSequence;
	onClose(): void;
	initialStep?: number;
}> = ({ impression, onClose, initialStep = 0 }) => {
	const stepImages = useStepImages(impression);
	const slots = useSlots(impression);
	useFirstImpression(slots[0], initialStep);

	const {
		maximumViewedStep,
		onSetSlide,
		advance,
		stepEnterTime,
		step: currentStep,
		setStep,
		stepLimit,
	} = useFlowSteps(initialStep, {
		onFinish: onClose,
		stepLimit: slots.length,
	});

	useExposureTracking(currentStep, slots[currentStep], impression);

	const rendererStep = (renderedStep: number) => {
		return (
			<>
				{renderStepX1(
					renderedStep,
					{
						maximumViewedStep,
						stepLimit,
						slots,
						stepImages,
						onSetSlide,
						stepEnterTime,
						impression,
						advance,
						onClose,

						slotRender,
					},
					{
						Splash: (slideData, events, props) => (
							<SplashScreen
								image={impression.brand.mg?.image_mobile || ''}
								brand={impression.brand.logo}
								brandColor={
									impression.brand.background?.[0] || ''
								}
								{...props}
								{...slideData}
								{...events}
								text={fromMarkAst(slideData.text)}
								subtext={fromMarkAst(slideData.subtext)}
								onGetStarted={events.onNext}
							/>
						),
					},
				)}
			</>
		);
	};

	const onLayoutClose = () => {
		const slot = slots[currentStep];

		analytics.ad.skip(slot?.data.experienceId || 'welcome', {
			...getAdTrackingAttributes(slot, currentStep, impression),
			timeVisible: Date.now() - stepEnterTime,
		});

		onClose();
	};

	const renderStepLayout = () => {
		return <div>...</div>;
	};

	return (
		<EventContainer name="step" attribute={currentStep}>
			<Layout
				statusText={astToNode(
					impression.statusOverride[currentStep] ||
						impression.statusText,
				)}
				currentStep={currentStep}
				setCurrentStep={setStep}
				onClose={onLayoutClose}
				steps={Array.from({ length: slots.length }).map((_, index) => (
					<StepRender key={index}>
						<Suspense fallback={renderStepLayout()}>
							<SuspendedRender>
								{() => rendererStep(index)}
							</SuspendedRender>
						</Suspense>
					</StepRender>
				))}
			/>
		</EventContainer>
	);
};
